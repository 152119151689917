import React, { Fragment, useState } from "react";
import { Container } from "react-bootstrap";
import ServiceTable from "../components/Service/Services";
import Sidebar from "../components/Sidebar/Sidebar";
import routes from "../routes";
import AdminNav from "../components/Navbars/AdminNavbar";
import ServiceFilter from "../components/Service/ServiceFilter";

export default function Customers() {
  const [name, setName] = useState();

  return (
    <Fragment>
      <Sidebar routes={routes} />
      <div className="main-panel">
        <AdminNav />
        <div className="content">
          <Container fluid>
            <ServiceFilter name={name} setName={setName} />
            <ServiceTable name={name} setName={setName} />
          </Container>
        </div>
      </div>
    </Fragment>
  );
}
