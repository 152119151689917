import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { selectToken } from "./authSlice";
import Cookies from "js-cookie";

const API_BASE_URL = process.env.REACT_APP_API_URL;

const getTokenFromCookies = () => {
  return Cookies.get("token");
};

export const VendorApi = createApi({
  reducerPath: "VendorApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,

    tagTypes: ["Vendor"],
    prepareHeaders: (headers, { getState }) => {
      const token = selectToken(getState()) || getTokenFromCookies();

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    GetVendor: builder.query({
      query: ({ page, name }) =>
        `owner/admin?page=${page}${name ? `&keyword=${name}` : ""} `,
      providesTags: ["Vendor"],
    }),
    deleteVendor: builder.mutation({
      query: (id) => ({
        url: `owner/admin/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Vendor"],
    }),
  }),
});

export const { useGetVendorQuery, useDeleteVendorMutation } = VendorApi;
