const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    layout: "/admin",
  },
  {
    path: "/product",
    name: "Products",
  },
  {
    path: "/services",
    name: "Services",
  },
  {
    path: "/order",
    name: "Order",
  },
  {
    path: "/vender",
    name: "Venders",
  },
  {
    path: "/user",
    name: "User",
  },
  {
    path: "/earning",
    name: "Earning",
  },
];

export default dashboardRoutes;
