import React, { Fragment } from "react";
import { Card } from "react-bootstrap";
import Noti from "../../assets/img/more.png";
import Noti1 from "../../assets/img/more1.png";
import { Nav, Dropdown } from "react-bootstrap";
import { customers } from "../../Data/Data";
import { Link } from "react-router-dom";
import { useGetVendorQuery } from "../../redux/slice/vendorApi";

export default function DashboardVendors() {
  const page = 1;
  const {
    data: vendorData,
    error,
    isLoading,
  } = useGetVendorQuery({ page: page });
  return (
    <Fragment>
      <Card className="workorder customer">
        <Card.Header>
          <Card.Title as="h4">Venders</Card.Title>
          <Link to="/vender" className="yellow">
            View all
          </Link>
        </Card.Header>

        <div className="workorderheight">
          {vendorData?.data.map((item, i) => {
            return (
              <Card.Body key={i}>
                <div className="row workdetail" key={i}>
                  <div className="col-sm-10">
                    <h3 className="workdetailNAme">{item.name}</h3>
                    <p>{item?.email}</p>
                  </div>
                  <div className="col-sm-2">
                    <h3>Products</h3>
                    <p>{item?.productCount}</p>
                  </div>
                </div>
              </Card.Body>
            );
          })}
        </div>
      </Card>
    </Fragment>
  );
}
