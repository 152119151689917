// src/components/PackageList.js
import React, { useState, useCallback } from "react";
import Pagination from "../Pagination/Pagination";
import { Nav, Dropdown } from "react-bootstrap";
import Noti from "../../assets/img/more.png";
import DeleteModal from "../Modals/DeleteModal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  useGetProductQuery,
  ProductApi,
  useDeleteProductMutation,
} from "../../redux/slice/productApi";

const Producttable = ({ name, setName }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const {
    data: product,
    error,
    isLoading,
  } = useGetProductQuery({
    page: currentPage,
    name: name,
  });

  const [deletePackage] = useDeleteProductMutation();
  const [id, setId] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  // Memoize the delete function
  const handleDelete = useCallback(async () => {
    try {
      await deletePackage(id);
      toast.success("Product Deleted SuccessFully");
    } catch (error) {
      console.error("Error deleting package:", error);
      toast.error("Failed to delete package");
    }
  }, [id, deletePackage]);

  const handleModal = (id) => {
    setModalOpen(true);
    setId(id);
  };

  const NavigationToProfileUpdate = (id) => {
    navigate(`/edit-package`, {
      state: id,
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);

    fetchProductData(page);
  };
  const fetchProductData = async (page) => {
    try {
      await ProductApi.useGetProductQuery({ page, name });
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  return (
    <div className="routedashboard mainpage customertable">
      <div className="ct-chart" id="chartActivity">
        <table>
          <thead>
            <tr>
              <th>Product Name</th>

              <th>Description</th>
              <th>Price</th>
              <th> Category</th>
              <th>Rating</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {product?.products && product.products.length > 0 ? (
              product.products.map((data, i) => (
                <tr key={data._id}>
                  {/* ... Your table row data ... */}
                  <td>{data?.name}</td>

                  <td>{data?.description}</td>
                  <td>{data?.price}</td>
                  <td>{data?.category?.name}</td>
                  <td>{data?.ratings}</td>
                  <td>
                    <Dropdown as={Nav.Item} className="notidrop">
                      <Dropdown.Toggle
                        data-toggle="dropdown"
                        id="dropdown-67443507"
                        variant="default"
                        className="m-0"
                      >
                        <img src={Noti} alt="boximg" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => NavigationToProfileUpdate(data)}
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleModal(data._id)}>
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </table>
        {product && (
          <Pagination
            currentPage={currentPage}
            TotalPages={Math.ceil(
              product?.productsCount / product?.resultPerPage
            )}
            paginate={handlePageChange}
          />
        )}
        <DeleteModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          handleDelete={handleDelete}
          id={id}
        />
      </div>
    </div>
  );
};

export default Producttable;
