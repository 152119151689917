import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "../src/maincss/Style.css";
import "../src/maincss/faiz.css";
import "../src/maincss/ahsan.css";
import "../src/maincss/responsive.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/css/animate.min.css";
import "../src/assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "../src/assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Subscriber from "./views/Subscriber";
import Dashboard from "./views/Dashboard";
import Loginpage from "./views/Loginpage";
import ProtectedRoute from "./components/Login/ProtectedRoute";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ToastContainer } from "react-toastify";
import Products from "./views/Products";
import Order from "./views/Order";
import Earning from "./views/Earning";
import Services from "./views/Services";
import Vender from "./views/Vender";
import User from "./views/User";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Loginpage />} />
        <Route element={<ProtectedRoute />}>
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/product" element={<Products />} />
          <Route exact path="/order" element={<Order />} />
          <Route exact path="/vender" element={<Vender />} />
          <Route exact path="/user" element={<User />} />
          <Route exact path="/earning" element={<Earning />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </Provider>
);
