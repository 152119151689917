import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../redux/slice/authSlice";
import { api } from "../redux/slice/packageApi";
import { subscriptionapi } from "../redux/slice/subscriptionApi";
import { UserApi } from "../redux/slice/userApi";
import { VendorApi } from "../redux/slice/vendorApi";
import { OrderApi } from "./slice/orderApi";
import { ProductApi } from "./slice/productApi";
import { productServiceApi } from "./slice/productServiceApi";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [VendorApi.reducerPath]: VendorApi.reducer,
    [productServiceApi.reducerPath]: productServiceApi.reducer,

    [ProductApi.reducerPath]: ProductApi.reducer,
    [UserApi.reducerPath]: UserApi.reducer,
    [OrderApi.reducerPath]: OrderApi.reducer,
    [api.reducerPath]: api.reducer,

    [subscriptionapi.reducerPath]: subscriptionapi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      api.middleware,
      subscriptionapi.middleware,
      UserApi.middleware,
      VendorApi.middleware,
      OrderApi.middleware,
      ProductApi.middleware,
      productServiceApi.middleware
    ),
});

export default store;
