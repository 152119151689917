import React, { useState } from "react";
import { Fragment } from "react";
import { useGetProductQuery, ProductApi } from "../../redux/slice/productApi";

const ServiceFilter = ({ name, setName }) => {
  return (
    <Fragment>
      <form className="myfilters1 custFiltwer w-100">
        <input
          onChange={(e) => setName(e.target.value)}
          value={name}
          type="text"
          placeholder="Search for Name"
        />
      </form>
    </Fragment>
  );
};

export default ServiceFilter;
