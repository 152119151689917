import React, { Fragment } from 'react'
import {Card} from "react-bootstrap";
import Noti from "../../assets/img/more.png"
import Noti1 from "../../assets/img/more1.png"
import { Nav, Dropdown } from "react-bootstrap";
import { workorder } from '../../Data/Data'


export default function Workorder() {
  return (
    <Fragment>

        <Card className='workorder'>
              <Card.Header>
                <Card.Title as="h4">Order</Card.Title>
                        <Dropdown as={Nav.Item} className="notidrop" >
                            <Dropdown.Toggle   data-toggle="dropdown"  id="dropdown-67443507"  variant="default"  className="m-0" >
                            <img src={Noti} alt='boximg' />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item  href="#pablo"  onClick={(e) => e.preventDefault()} > Edit </Dropdown.Item>
                              <Dropdown.Item  href="#pablo"  onClick={(e) => e.preventDefault()} > Update</Dropdown.Item>
                              <Dropdown.Item  href="#pablo"  onClick={(e) => e.preventDefault()} > Delete </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
              </Card.Header>
              <div className='workorderheight'>
              {workorder.map((data) => {
              return (
            <></>
                 )
                })}
                </div>
            </Card>
            
    </Fragment>
  )
}
