import React, { Component } from "react";
import { useLocation, NavLink } from "react-router-dom";
import "../../maincss/responsive.css";
import { Accordion, Nav } from "react-bootstrap";
import Logo from "../../assets/img/logo.png";
import Avatar from "../../assets/img/avatar.png";

function Sidebar({ routes }) {
  const location = useLocation();
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  return (
    <div className="sidebar">
      <div className="sidebar-background" />
      <div className="sidebar-wrapper sideBarComp">
        <div className="logo d-flex align-items-center justify-content-start">
          <div className="logo-img">
            <h3>GOTDIAL</h3>
            {/* <img src={Logo} alt="..." className="logo" /> */}
          </div>
        </div>
        <Nav>
          {routes.map((prop, key) => {
            if (prop.name === "Reports") {
              return (
                <li
                  className={
                    prop.upgrade
                      ? "active active-pro"
                      : activeRoute(prop.layout + prop.path)
                  }
                  key={key}
                >
                 
                </li>
              );
            }

            

            if (!prop.redirect) {
              return (
                <li
                  className={
                    prop.upgrade
                      ? "active active-pro"
                      : activeRoute(prop.layout + prop.path)
                  }
                  key={key}
                >
                  <NavLink to={prop.path} className="nav-link">
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </NavLink>
                </li>
              );
            }

            return null;
          })}
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;
