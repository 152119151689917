import React, { Fragment, useState } from "react";
import { Container } from "react-bootstrap";
import Packagefilter from "../components/Packages/Packagefilter";
import Sidebar from "../components/Sidebar/Sidebar";
import routes from "../routes";
import AdminNav from "../components/Navbars/AdminNavbar";
import Producttable from "../components/Packages/Producttable";

export default function Products() {
  const [name, setName] = useState();
  return (
    <Fragment>
      <Sidebar routes={routes} />
      <div className="main-panel">
        <AdminNav />
        <div className="content">
          <Container fluid>
            {/* <Packagesearch /> */}
            <Packagefilter setName={setName} name={name} />
            <Producttable setName={setName} name={name} />
          </Container>
        </div>
      </div>
    </Fragment>
  );
}
