import React, { Fragment, useState } from "react";
import { Container } from "react-bootstrap";
import OrderList from "../components/Order/OrderList";
import Sidebar from "../components/Sidebar/Sidebar";
import routes from "../routes";
import AdminNav from "../components/Navbars/AdminNavbar";
import OrderFilter from "../components/Order/OrderFilter";

export default function Customers() {
  const [name, setName] = useState();

  return (
    <Fragment>
      <Sidebar routes={routes} />
      <div className="main-panel">
        <AdminNav />
        <div className="content">
          <Container fluid>
            {/* <OrderFilter name={name} setName={setName} /> */}
            <OrderList name={name} setName={setName} />
          </Container>
        </div>
      </div>
    </Fragment>
  );
}
