import React from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
export default function Customersearch() {
  return (
    <Fragment>
      <div className="row customers owner">
        <div className="col-sm-5 equipmentssss"></div>
        <div className="col-sm-7 right equipmentssss"></div>
      </div>
    </Fragment>
  );
}
