// src/components/PackageList.js
import React, { useState, useCallback, useEffect } from "react";
import Pagination from "../Pagination/Pagination";
import { Nav, Dropdown } from "react-bootstrap";
import Noti from "../../assets/img/more.png";
import DeleteModal from "../Modals/DeleteModal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  useDeleteProductServiceMutation,
  useGetProductServiceQuery,
} from "../../redux/slice/productServiceApi";

const services = ({ name }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const {
    data: productServiceApi,
    error,
    isLoading,
  } = useGetProductServiceQuery({
    page: currentPage,
    name: name,
  });
  const [deletePackage] = useDeleteProductServiceMutation();
  const [id, setId] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  // Memoize the delete function
  const handleDelete = useCallback(async () => {
    try {
      await deletePackage(id);
      toast.success("Customer Delete SuccessFully");
    } catch (error) {
      console.error("Error deleting package:", error);
      toast.error("Failed to delete package");
    }
  }, [id, deletePackage]);

  const handleModal = (id) => {
    setModalOpen(true);
    setId(id);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);

    fetchProductServiceData(page);
  };
  const fetchProductServiceData = async (page) => {
    try {
      const productServiceApi = await ProductApi.useGetProductQuery({
        page,
        name,
      });
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  const NavigationToProfileUpdate = (id) => {
    navigate(`/edit-package`, {
      state: id,
    });
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  console.log(productServiceApi, "dsa");

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, []);

  return (
    <div className="routedashboard mainpage customertable">
      <div className="ct-chart" id="chartActivity">
        <table>
          <thead>
            <tr>
              <th>Service Name</th>
              <th>Price</th>
              <th>Date</th>
              {/* <th>Price</th> */}
              <th> </th>
            </tr>
          </thead>

          <tbody>
            {productServiceApi?.data && productServiceApi.data.length > 0 ? (
              productServiceApi.data.map((data, i) => (
                <tr key={data._id}>
                  {/* ... Your table row data ... */}
                  <td>{data?.name}</td>
                  <td>{data?.price}</td>
                  <td>{data?.description}</td>
                  <td>
                    <Dropdown as={Nav.Item} className="notidrop">
                      <Dropdown.Toggle
                        data-toggle="dropdown"
                        id="dropdown-67443507"
                        variant="default"
                        className="m-0"
                      >
                        <img src={Noti} alt="boximg" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleModal(data._id)}>
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </table>
        <DeleteModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          handleDelete={handleDelete}
          id={id}
        />
        {productServiceApi && (
          <Pagination
            currentPage={currentPage}
            TotalPages={Math.ceil(
              productServiceApi?.totalCount / productServiceApi?.resultPerPage
            )}
            paginate={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};

export default services;
