import React from 'react'
import { Fragment } from 'react'
import Login from '../components/Login/Login'

function Loginpage() {
  return (
    <Fragment>
        <Login/>
    </Fragment>
  )
}

export default Loginpage