import React, { useState } from "react";
import { Fragment } from "react";

const Customerfilter = ({ name, setName }) => {
  // const [name, setName] = useState("");

  return (
    <Fragment>
      <form className="myfilters1 custFiltwer">
        <input
          onChange={(e) => setName(e.target.value)}
          value={name}
          type="text"
          placeholder="Search for Name"
        />
      </form>
    </Fragment>
  );
};

export default Customerfilter;
