import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { selectToken } from "./authSlice";
import Cookies from "js-cookie";

const API_BASE_URL = process.env.REACT_APP_API_URL;

const getTokenFromCookies = () => {
  return Cookies.get("token");
};

export const productServiceApi = createApi({
  reducerPath: "productServiceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,

    tagTypes: ["productService"],
    prepareHeaders: (headers, { getState }) => {
      const token = selectToken(getState()) || getTokenFromCookies();

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    GetProductService: builder.query({
      query: ({ page, name }) =>
        `/owner/productservice?page=${page}${name ? `&keyword=${name}` : ""}`,
      providesTags: ["productService"],
    }),
    DeleteProductService: builder.mutation({
      query: (id) => ({
        url: `/owner/products/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["productService"],
    }),
  }),
});

export const { useGetProductServiceQuery, useDeleteProductServiceMutation } =
  productServiceApi;
