// src/components/PackageList.js
import React, { useState, useCallback } from "react";
import Pagination from "../Pagination/Pagination";
import { Nav, Dropdown } from "react-bootstrap";
import Noti from "../../assets/img/more.png";
import DeleteModal from "../Modals/DeleteModal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  useDeleteOrderMutation,
  useGetOrderQuery,
} from "../../redux/slice/orderApi";
import moment from "moment";

const OrderList = ({ name }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const {
    data: Orders,
    error,
    isLoading,
  } = useGetOrderQuery({ page: currentPage, name: name });
  const [deleteOrder] = useDeleteOrderMutation();
  const [id, setId] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Memoize the delete function
  const handleDelete = useCallback(async () => {
    try {
      await deleteOrder(id);
      toast.success("Data Delete SuccessFully");
    } catch (error) {
      console.error("Error deleting package:", error);
      toast.error("Failed to delete package");
    }
  }, [id, deleteOrder]);

  const handleModal = (id) => {
    setModalOpen(true);
    setId(id);
  };

  const NavigationToProfileUpdate = (id) => {
    navigate(`/edit-package`, {
      state: id,
    });
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="routedashboard mainpage customertable">
      <div className="ct-chart" id="chartActivity">
        <table>
          <thead>
            <tr>
              <th>Order #</th>
              <th>Product Name</th>
              <th>Total Price</th>

              <th>Location</th>
              <th>Status</th>
              <th>Date</th>

              <th> </th>
            </tr>
          </thead>

          <tbody>
            {Orders?.data && Orders.data.length > 0 ? (
              Orders.data.map((data, i) => (
                <tr key={data._id}>
                  {/* ... Your table row data ... */}
                  <td>{i + 1}</td>
                  <td>
                    {data?.orderItems?.map((item, i) => (
                      <p>{item?.product?.name}</p>
                    ))}
                  </td>
                  <td>{data?.totalPrice}</td>
                  <td>
                    {data?.shippingInfo?.city +
                      " , " +
                      data?.shippingInfo?.country}
                  </td>
                  <td>{data?.orderStatus}</td>
                  <td>
                    {" "}
                    <td>{moment(data?.createdAt).format("YYYY-MM-DD ")}</td>
                  </td>
                  <td>
                    <Dropdown as={Nav.Item} className="notidrop">
                      <Dropdown.Toggle
                        data-toggle="dropdown"
                        id="dropdown-67443507"
                        variant="default"
                        className="m-0"
                      >
                        <img src={Noti} alt="boximg" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => NavigationToProfileUpdate(data)}
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleModal(data._id)}>
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </table>
        <DeleteModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          handleDelete={handleDelete}
          id={id}
        />
      </div>
      {Orders && (
        <Pagination
          currentPage={currentPage}
          TotalPages={Math.ceil(Orders?.OrdersCount / Orders?.resultPerPage)}
          paginate={handlePageChange}
        />
      )}
    </div>
  );
};

export default OrderList;
